import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';

const ProductSectionNine = ({ productSection }: any) => {
  return (
    <div>
      <Grid>
        <Row>
          <Col xs={12}>
            <img
              src={productSection?.images[0]?.file?.url ?? ''}
              alt={productSection?.images[0]?.file?.fileName ?? ''}
              className="stretchImage"
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ProductSectionNine;
