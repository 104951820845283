import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import productStyles from './product.module.scss';
import ProductCarousel from '../components/product-carousel';
import Head from '../components/head';
import Layout from '../components/layout';
import Contact from '../components/contact';
import ProductSectionFive from '../components/product-section/product-section-five';
import ProductSectionFour from '../components/product-section/product-section-four';
import ProductSectionOne from '../components/product-section/product-section-one';
import ProductSectionSeven from '../components/product-section/product-section-seven';
import ProductSectionSix from '../components/product-section/product-section-six';
import ProductSectionThree from '../components/product-section/product-section-three';
import ProductSectionTwo from '../components/product-section/product-section-two';
import ProductSectionEight from '../components/product-section/product-section-eight';
import ProductSectionNine from '../components/product-section/product-section-nine';
import { useInView } from 'react-intersection-observer';

export const query = graphql`
  query($productPath: String!) {
    contentfulProduct(productPath: { eq: $productPath }) {
      name
      model
      productPath
      image {
        file {
          url
          fileName
        }
      }
      headline
      headlineOrange
      productDimensions
      awards {
        file {
          url
          fileName
        }
      }
      productSection {
        sectionName
        header
        paragraph {
          paragraph
        }
        quote
        quoteAuthor
        note1 {
          note1
        }
        note2 {
          note2
        }
        images {
          file {
            url
            fileName
          }
        }
        layoutType
        fullBleedQuote
        fullBleedQuoteAuthor
      }
    }
  }
`;

type DataProps = {
  contentfulProduct: {
    name: string;
    model: string;
    image: {
      file: {
        url: string;
        fileName: string;
      };
    };
    headline: string;
    headlineOrange: string;
    productDimensions: string;
    awards: {
      file: {
        url: string;
        fileName: string;
      };
    };
    productSection: [
      {
        sectionName: string;
        header: string;
        paragraph: {
          paragraph: string;
        };
        quote: string;
        quoteAuthor: string;
        note1: {
          note1: string;
        };
        note2: {
          note2: string;
        };
        images: [
          {
            file: {
              url: string;
              fileName: string;
            };
          },
        ];
        layoutType: number;
      },
    ];
    fullBleedQuote: string;
    fullBleedQuoteAuthor: string;
  };
};

const Product: React.FC<PageProps<DataProps>> = ({ data }) => {
  const options = { threshold: 0.5 };
  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [imageRef, imageInView, imageEntry] = useInView(options);

  return (
    <Layout>
      <div className="products">
        <Grid>
          <Row className="head">
            <Col xs={12} md={4} className="vert-center">
              <Head title={data?.contentfulProduct?.name ?? ''} />
              <div ref={titleRef}>
                <div
                  className={
                    titleInView
                      ? productStyles.slideUShow
                      : productStyles.slideUHide
                  }
                >
                  <h2>
                    {data?.contentfulProduct?.name ?? ''}{' '}
                    <strong>{data?.contentfulProduct?.model ?? ''}</strong>
                  </h2>
                  <h1>
                    {data?.contentfulProduct?.headline ?? ''}{' '}
                    <span>{data?.contentfulProduct?.headlineOrange ?? ''}</span>
                  </h1>
                  <h4>{data?.contentfulProduct?.productDimensions ?? ''}</h4>

                  <div className="alignTop">
                    <img
                      src={data?.contentfulProduct?.awards?.file?.url ?? ''}
                      alt={
                        data?.contentfulProduct?.awards?.file?.fileName ?? ''
                      }
                      width="50"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} mdOffset={2}>
              <div ref={imageRef}>
                <div
                  className={
                    imageInView
                      ? productStyles.fadeShow
                      : productStyles.fadeHide
                  }
                >
                  <img
                    src={data?.contentfulProduct?.image?.file?.url ?? ''}
                    alt={data?.contentfulProduct?.image?.file?.fileName ?? ''}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
        {data?.contentfulProduct?.productSection?.map((section: any, index) => {
          switch (section.layoutType) {
            case 1:
              return <ProductSectionOne key={index} productSection={section} />;
            case 2:
              return <ProductSectionTwo key={index} productSection={section} />;
            case 3:
              return (
                <ProductSectionThree key={index} productSection={section} />
              );
            case 4:
              return (
                <ProductSectionFour key={index} productSection={section} />
              );
            case 5:
              return (
                <ProductSectionFive key={index} productSection={section} />
              );
            case 6:
              return <ProductSectionSix key={index} productSection={section} />;
            case 7:
              return (
                <ProductSectionSeven key={index} productSection={section} />
              );
            case 8:
              return (
                <ProductSectionEight key={index} productSection={section} />
              );
            case 9:
              return (
                <ProductSectionNine key={index} productSection={section} />
              );
            default:
              return null;
          }
        })}
        <Grid>
          <Row>
            <Col md={12}>
              <ProductCarousel />
            </Col>
          </Row>
        </Grid>

        <div className="grey">
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

export default Product;
